export default {
  famous: [
    '磨牙特a，今天也是精神满满并且超爱妥妥的一天！b',
    '磨牙特a，今天也是爱妥妥的一天！b',
    '磨牙特a，今天也是超爱妥妥的一天！b',
    '磨牙特a，给妥妥上舰长！b',
    '磨牙特a，我生是妥妥的人，死是妥妥的鬼！b',
    '磨牙特a，吃了果冻啊，喝了脉动啊，但还是忍不住对妥妥心动啊♡b',
    '磨牙特a，呜呜呜呜呜呜呜呜呜呜妥妥她怎么会这么美呜呜呜呜呜呜呜呜呜！b',
    '磨牙特a，我叫妥妥的磨牙特。b',
    '磨牙特a，妥妥是娇小可人。b',
    '磨牙特a，有一说一妥妥唱小白船真好听！b',
    '磨牙特a，梦里有可爱的妥妥！b',
    '磨牙特a，今天爱妥妥的心也像这蓝天一般无边无际~b',
    '磨牙特a，今天又是爱妥爱沐娶妥妥的一天~b',
    '磨牙特a，她就像一只可爱的小猫咪一样又活泼又令人着迷♡b',
    '磨牙特a，爱生活！爱妥妥！银河以北，妥妥最美！银河以南，妥妥最甜！妥妥勇敢飞，爱妥永相随！b',
    '磨牙特a，老子关注虽多，却只爱妥妥一个！b',
    '磨牙特a，不得不说妥妥成长的越来越可爱了♡b',
    '磨牙特a，她无论何时无论什么样子都超好看♡b',
    '磨牙特a，爷只喜欢妥妥♡b',
    '磨牙特a，今天也是爱着那仙气十足又英姿飒爽甜美可爱的妥妥♡b',
    '磨牙特a，很简单，因为她有很多种风格无论是酷酷的妥妥还是可爱到让人爱不释手的样子她都。有b',
    '磨牙特a，还想被妥妥骂八嘎hentai无路赛。b',
    '磨牙特a，特特死前想看妥妥雾蓝灰。b',
    '磨牙特a，妥妥真美！b',
    '磨牙特a，只要不是我心目中可爱美丽帅气妖娆性感倾国倾城闭月羞花妩媚动人的妥妥心疼就行b',
    '磨牙特a，美好的一天，从看到美丽的妥妥开始♡b',
    '磨牙特a，我只喜欢那个可可爱爱说很委屈的可爱妥妥呢♡b',
    '磨牙特a，妥子哥美艳不可方物！！笑容即可倾国倾城！！既有魅力又有气质的妥妥好美！！！！b',
    '磨牙特a，我以前上班累了面对那些皮笑肉不笑的人心累了回来一看沐哥妥妥直播我的心情就能放松很多。b',
    '磨牙特a，想念那可爱的妥妥酱♡b',
    '磨牙特a，我的第六感很准，就突然惊醒，就看到了美丽可爱迷人动人俊俏帅气的妥妥了♡b',
    '磨牙特a，啊，妥妥无论是素颜还是化妆还是欧美风还是成熟风还是可爱风还是22336699或者cos真的都好美啊♡b',
    '磨牙特a，喜欢妥妥，她的每一秒都不想错过♡b',
    '磨牙特a，我戒不掉名为妥妥的瘾♡b',
    '我拍了拍磨牙特深爱妥妥的心♡'
  ],
  bosh: [
    '现在，解决x的问题，是非常非常重要的。 所以，',
    '我们不得不面对一个非常尴尬的事实，那就是x的发生，到底需要如何做到，不x的发生，又会如何产生。',
    '而这些并不是完全重要，更加重要的问题是，',
    '那么，x到底应该如何实现呢？',
    '带着这些问题，我们来审视一下x。',
    '所谓x，关键是x需要如何写。',
    '我们一般认为，抓住了问题的关键，其他一切则会迎刃而解。',
    '问题的关键究竟是为何? ',
    'x因何而发生？',
    '每个人都不得不面对这些问题。在面对这种问题时，应该怎么做呢？',
    '一般来讲，我们都会慎重的考虑这件事。',
    '要想清楚，x，到底是一种怎么样的存在。 ',
    '了解清楚x到底是一种怎么样的存在，是解决一切问题的关键。',
    '就我个人来说，x对我的意义，不能不说非常重大。 ',
    '本人也是经过了深思熟虑，在每个日日夜夜思考这个问题。 ',
    'x，发生了会如何，不发生又会如何。 ',
    '在这种困难的抉择下，本人思来想去，寝食难安。',
    '生活中，若x出现了，我们就不得不考虑它出现了的事实。 ',
    '这种事实对本人来说意义重大，相信对这个世界也是有一定意义的。',
    '我们都知道，只要有意义，那么就必须慎重考虑。',
    '既然如此，',
    '那么，',
    '我认为，',
    '一般来说，',
    '总结的来说，',
    '既然如何，',
    '经过上述讨论，',
    '这样看来，',
    '从这个角度来看，',
    '我们不妨可以这样来想：',
    '这是不可避免的。 ',
    '可是，即使是这样，x的出现仍然代表了一定的意义。 ',
    'x似乎是一种巧合，但如果我们从一个更大的角度看待问题，这似乎是一种不可避免的事实。 ',
    '在这种不可避免的冲突下，我们必须解决这个问题。 ',
    '对我个人而言，x不仅仅是一个重大的事件，还可能会改变我的人生。 '
  ],
  after: [
    '这不禁令我深思。 ',
    '带着这句话，我们还要更加慎重的审视这个问题：',
    '这启发了我。 ',
    '我希望诸位也能好好地体会这句话。 ',
    '这句话语虽然很短，但令我浮想联翩。 ',
    '这句话看似简单，但其中的阴郁不禁让人深思。 ',
    '这句话把我们带到了一个新的维度去思考这个问题：',
    '这似乎解答了我的疑惑。 '
  ],
  before: [
    '曾经说过',
    '在不经意间这样说过',
    '说过一句著名的话',
    '曾经提到过',
    '说过一句富有哲理的话'
  ],
};
